import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "@environments/environment";
import { AOH_BASE_URL, brain_base_url, ENTITLE_BASE_URL } from "@asset-owner-portal/api/tokens";
import { DELOITTEAI_ENVIRONMENT } from "@shared/environment";

if (environment.production) {
    enableProdMode();
}

function MyModuleFactory(): Promise<any> {
    const isMsIE = (window.document as any).documentMode;

    return !isNaN(isMsIE)
        ? import("@shared/page_internet_explorer/ie.module").then((module) => module.IEModule)
        : import("./app/app.module").then((module) => module.AppModule);
}

MyModuleFactory().then((module) => {
    platformBrowserDynamic([{
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: AOH_BASE_URL,
        useValue: environment.brain_base_url + '/repository'
    }, {
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: ENTITLE_BASE_URL,
        useValue: environment.brain_base_url + '/services/subscriptions'
    }, {
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: brain_base_url,
        useValue: environment.brain_base_url
    }, {
        provide: DELOITTEAI_ENVIRONMENT,
        useValue: {
            context: "asset_owner_hub",
            ...environment
        }
    }])
        .bootstrapModule(module).catch(err => console.error(err));
});
